const Data = {
  menuItems: [
    {
      name: "Home",
      path: "top",
    },
    {
      name: "Product",
      path: "mainFeatures",
      offset: "-40",
    },
    {
      name: "Roadmap",
      path: "roadmap",
      offset: "-80",
    },
    {
      name: "Token",
      path: "token",
      offset: "-80",
    },
    {
      name: "Team",
      path: "team",
      offset: "-80",
    }
  ],
}
export default Data
