import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { FaEnvelope } from "react-icons/fa"
import Subscribe from "sections/Subscribe"
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import { List, ListItem } from "reusecore/List"
import { Link as OnepageLink } from "react-scroll"

import cryptikFooterLogo from "assets/images/logo.png"
import data from "assets/data/footer"
import FooterWrapper from "./footer.style"

const Footer = () => {

  const [defaultPath, setDefaultPath] = useState('');
  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/privacy-policy' || path === '/roadmap') {
      setDefaultPath('/');
    }
  }, []);

  return (
    <FooterWrapper>
      <Box className="footer-content-wrapper">
        <Subscribe />
        <div style={{ marginTop: '80px' }}></div>
        <Container>
          <Row>
            <Col className="col-12 lg-4 sm-6">
              <Box className="footer-widgets company-desc">
                <img src={cryptikFooterLogo} alt="cryptik footer logo" />
                <Text>
                  With the help of blockchain we provide verified proof of skills, enforce payment discipline, ensure quality audits and mediate any disputes.
                </Text>

                <Box className="contact-info" id="contact-email">
                  <a href="mailto:office@worknskill.com">
                    {" "}
                    <FaEnvelope /> office@worknskill.com{" "}
                  </a>
                </Box>
              </Box>
            </Col>
            <Col className="col-12 lg-4 sm-6">
              <Box className="footer-widgets">
                {/* We need to add some better title here */}
                <Heading as="h2">Policies</Heading>
                <List>
                  <ListItem>
                    <Link to="/privacy-policy">Privacy policy</Link>
                  </ListItem>
                  {/* <ListItem>
                    <Link to="#">Terms</Link>
                  </ListItem> */}
                  <ListItem>
                    <Link to="/privacy-policy">Cookies</Link>
                  </ListItem>
                </List>
              </Box>
            </Col>
            <Col className="col-8 lg-4 sm-6">
              <Box className="footer-widgets footer-links">
                {/* We need to add some better title here */}
                <Heading as="h2">Links</Heading>
                <List>
                  <ListItem>
                    {defaultPath
                      ? <a href="/">Product</a>
                      :
                      <OnepageLink
                        className="footer-link"
                        to="mainFeatures"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={700}
                      >
                        Product
                      </OnepageLink>
                    }
                  </ListItem>
                  <ListItem>
                    {defaultPath
                      ? <a href="/">Token</a>
                      :
                      <OnepageLink
                        className="footer-link"
                        to="token"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={700}
                      >
                        Token
                      </OnepageLink>
                    }

                  </ListItem>
                  <ListItem>
                    {defaultPath
                      ? <a href="/">Roadmap</a>
                      :
                      <OnepageLink
                        className="footer-link"
                        to="roadmap"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={700}
                      >
                        Roadmap
                      </OnepageLink>
                    }
                  </ListItem>
                  <ListItem>
                    {defaultPath
                      ? <a href="/">Team</a>
                      :
                      <OnepageLink
                        className="footer-link"
                        to="team"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={700}
                      >
                        Team
                      </OnepageLink>
                    }
                  </ListItem>
                </List>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col className="xs-12">
              <Box className="footer-social-links">
                {data.links.map((item, index) => (
                  <a href={item.url} key={index} target="_blank" rel="noreferrer">
                    {item.icon}
                  </a>
                ))}
              </Box>

              <Box className="copyright-text">
                <Text>&copy; Work 'n Skill | All rights reserved 2022</Text>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </FooterWrapper>
  )
}

export default Footer;
