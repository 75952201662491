import React from 'react';

import {
    FaFacebookF,
    FaLinkedinIn,
    FaInstagram,
} from "react-icons/fa";

export default {
    links: [
        {
            icon: <FaFacebookF />,
            url: "https://www.facebook.com/WorkNSkill"
        },
        {
            icon: <FaLinkedinIn />,
            url: "https://www.linkedin.com/company/work-n-skill/"
        },
        {
            icon: <FaInstagram />,
            url: "https://www.instagram.com/work.n.skill/"
        }
    ]
}