import React, { useState } from "react"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Button from "reusecore/Button"
import Input from "reusecore/Form/Input"
import { SectionTitle } from "reusecore/SectionTitle"

import subscribe from "../../common"

import SubscribeWrapper from "./subscribe.style"

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [validEmailError, setValidEmailError] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (event) => {
    setEmailError(false);
    setShowError(false);
    setValidEmailError(false);
    const value = event.target.value;
    setEmail(value);
  }

  const handleSubscribe = () => {
    subscribe({
      email: email,
      subscribed_to_updates: true,
      applied_as_beta_user: false,
      list_id: "c558a7dc-396c-4419-b39e-56a608b63e45"
    })
      .then((response) => {
        setSubscribed(true);
      }).catch(error => {
        const jsonError = JSON.parse(error.request.response)
        if (jsonError.error_first_message === 'To polje ne sme biti prazno.') {
          setEmailError(true);
        }
        else if (jsonError.error_first_message === 'Vnesite veljaven elektronski naslov.') {
          setValidEmailError(true);
        }
        else {
          setShowError(true);
        }
      });
  }
  return (
    <SubscribeWrapper id="contact">
      <Box className="subscribe-box-wrapper">
        <Container>
          <Row>
            <Col className="col-12">
              <Box className="subscribe-box-bg">
                <Row>
                  <Col className="lg-6 offset-lg-3 xs-12">
                    <SectionTitle>
                      <Heading> Don’t miss out, Stay updated </Heading>
                      {/* <Text>
                        Sign up for updates and market news. Subscribe to our
                        newsletter and receive update about ICOs and crypto
                        tips.
                      </Text> */}
                    </SectionTitle>
                  </Col>
                </Row>
                <Row>
                  <Col className="lg-8 offset-lg-2 xs-12">
                    <Box className="form-box">
                      {!subscribed ?
                        <>
                          <Input
                            type="text"
                            placeholder="Enter your email address . . ."
                            handleEmailChange={(e) => handleEmailChange(e)}
                          />
                          <Button onClick={() => handleSubscribe()}>Subscribe</Button>
                        </>
                        :
                        <div style={{ 'textAlign': 'center' }}>
                          <span className="successfuly-subscribed">You have successfuly subscribed to newsletter!</span>
                        </div>}
                      {emailError && <div className="subscribe-error"><span>Enter email!</span></div>}
                      {showError && <div className="subscribe-error"><span>There was an error, please try again!</span></div>}
                      {validEmailError && <div className="subscribe-error"><span>Enter a valid email!</span></div>}
                    </Box>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </SubscribeWrapper>
  )
}

export default Subscribe
